import axios from 'axios';
const Axios = axios.create({
  baseURL: 'https://easyco.kopsyahmsi.com/api/api/'
})
const authLogin = (payload) => {
  return Axios.post('/authenticate/login', payload)
}
const infoRembug = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/tpl/information/rembug', payload, config)
}
const infoMember = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/tpl/information/member', payload, config)
}
const transSetoranDeposit = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/tpl/transaction/deposit', payload, config)
}
const transSetoranProses = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/tpl/transaction/process_deposit', payload, config)
}
// Anggota
const anggotaCreate = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/anggota/create', payload, config);
}
// Pembiayaan
const pembiayaanCreate = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/pengajuan/create', payload, config);
}
// Produk Tabungan
const produkTabungan = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/prdtabungan/read', payload, config);
}
// Produk Tabungan
const registrasiTabungan = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/tabungan/registrasi', payload, config);
}
const anggotaKeluar = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/anggotamutasi/create', payload, config);
}
const rekeningTabunganAnggota = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/tabungan/read', payload, config);
}
const tutupTabunganAnggota = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/tabungan/tutup', payload, config);
}
const readRekeningPembiayaan = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/registrasiakad/read', payload, config);
}
const readRekeningDroping = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/registrasiakad/read_droping', payload, config);
}
const prosesPenciran = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/registrasiakad/approve', payload, config);
}
const saldoAnggota = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.get('/anggotamutasi/saldo_anggota?' + payload, config);
}

const rembugRead = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/rembug/read', payload, config);
}

const anggotaRead = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/anggota/read', payload, config);
}

const getProfilAnggota = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/laporan/list/pdf/profil_anggota', payload, config);
}

const getRekeningTabungan = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/tabungan/get_rekening', payload, config);
}

const getRekeningPembiayaan = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/tpl/information/member_financing', payload, config);
}

const getMemberInformationHistoryFinancing = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/member/information/history_financing', payload, config);
}

const getMemberInformationHistorySaving = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/member/information/history_saving', payload, config);
}

const getLaporanListGetDetailSaving = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/laporan/list/get_detail_saving', payload, config);
}

const getTPLInformationMemberFinancing = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/tpl/information/member_financing', payload, config);
}

const getLaporanListTransaksiTabungan = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return Axios.post('/laporan/list/transaksi_tabungan', payload, config);
}

export default {
  authLogin,
  infoRembug,
  infoMember,
  transSetoranDeposit,
  transSetoranProses,
  anggotaCreate,
  pembiayaanCreate,
  produkTabungan,
  registrasiTabungan,
  saldoAnggota,
  anggotaKeluar,
  rekeningTabunganAnggota,
  tutupTabunganAnggota,
  readRekeningPembiayaan,
  readRekeningDroping,
  prosesPenciran,
  rembugRead,
  anggotaRead,
  getProfilAnggota,
  getRekeningTabungan,
  getRekeningPembiayaan,
  getMemberInformationHistoryFinancing,
  getMemberInformationHistorySaving,
  getLaporanListGetDetailSaving,
  getTPLInformationMemberFinancing,
  getLaporanListTransaksiTabungan,
}